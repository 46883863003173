import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'

import '@/static/css/bootstrap.min.css'
import '@/static/css/owl.carousel.min.css'
import '@/static/css/animate.min.css'
import '@/static/css/style.css'
import '@/static/css/magnific-popup.css'
import '@/static/css/font-awesome.min.css'
import '@/static/css/nice-select.css'
import '@/static/css/responsive.css'
// const globalVariable = "hello from global variable"
const app = createApp(App).use(router)
app.mixin({
    data() {
        return {
            aiList: [
                {
                    id: 1,
                    image: require('./static/picture/project/fig1_1.jpeg'),
                    title: '机器视觉在车辆交通中的应用',
                    shorttitle: '车辆交通',
                    link: 'P1',
                    delay: '0.3s'
                },
                {
                    id: 2,
                    image: require('./static/picture/project/fig2_2.jpeg'),
                    title: '机器视觉在生态保护中的应用',
                    shorttitle: '生态保护',
                    link: 'P2',
                    delay: '0.3s'
                },
                {
                    id: 3,
                    image: require('./static/picture/project/fig3_1.jpeg'),
                    title: '机器视觉在农业上的应用',
                    shorttitle: '农林畜牧',
                    link: 'P3',
                    delay: '0.4s'
                },
                {
                    id: 4,
                    image: require('./static/picture/project/fig4_3.jpeg'),
                    title: '机器视觉在工业中的应用',
                    shorttitle: '安全生产',
                    link: 'P4',
                    delay: '0.4s'
                }
            ],
            erplist: [
                {
                    id: 1,
                    image: require('./static/picture/feature-1.png'),
                    title: '行政流程',
                    link: 'A1',
                    delay: '0.3s',
                    note: '可高效筛选简历，库存管理能精准预测需求，财务管理自动处理数据，客户关系管理辅助分析客户需求。'
                },
                {
                    id: 2,
                    image: require('./static/picture/feature-2.jpeg'),
                    title: '智能问答',
                    link: 'A2',
                    delay: '0.3s',
                    note: '整合相关法律文书、行业规范、公司规章，迅速理解用户的意图，并以准确、清晰的回答反馈给用户。'
                },
                {
                    id: 3,
                    image: require('./static/picture/feature-3.jpeg'),
                    title: '培训平台',
                    link: 'A3',
                    delay: '0.3s',
                    note: '个性化定制培训内容，满足不同学员需求。智能分析学员表现，提供精准反馈。提升培训效率、降低成本。'
                },
                {
                    id: 4,
                    image: require('./static/picture/feature-4.jpeg'),
                    title: '信息整合',
                    link: 'A4',
                    delay: '0.3s',
                    note: '借助先进的AI技术，我们能够迅速且精准地收集并分析海量的市场信息，为商业决策提供有力支持。'
                }
            ]
        }
    }
})
app.mount('#app')