<template>
    <Header class1="header-one" isPro=True isP4=True></Header>
    <!--====== Banner Start ======-->
    <section class="breadcrumb-area">
        <div class="container">
            <div class="content">
                <h2 class="breadd wow fadeInUp">
                    机器视觉在工业中的应用
                </h2>
                <h2 class="breadd wow fadeInUp">
                    The Application of Machine Vision in Industry
                </h2>
                <comon pro="机器视觉" tit="安全生产" proip="projects"></comon>
            </div>
        </div>
    </section>
    <!--====== Banner End ======-->

    <section class="single-blog-details">
        <div class="container">
            <div class="row">
                <div class="blog-details">
                    <div class="main-content wow fadeInUp">
                        <div class="up-content">
                            <p class="text"><i class="fas fa-calendar-alt"></i>2023-12-28</p>
                        </div>
                    </div>

                    <p class="text">
                        机器视觉在工业和安全生产方面具有广泛的应用。它借助图像处理和计算机视觉技术，实现对目标物体的检测、识别、测量和控制。
                    </p>
                    <p class="text">
                        在工业领域，机器视觉可用于：
                    </p>
                    <ul class="contentul">
                        <li>
                            质量检测：对产品的缺陷、尺寸、形状等进行快速准确的检测。
                        </li>
                        <li>
                            生产流程监控：实时监测生产过程中的状态，确保操作符合规范。
                        </li>
                        <li>
                            自动化装配：引导机器人完成精确的零部件装配工作。
                        </li>
                    </ul>
                    <p class="text">
                        在安全生产方面，其应用包括：
                    </p>
                    <ul class="contentul">
                        <li>危险场景监测：如火灾、毒气等，及时发出警报。</li>
                        <img src="../../static/picture/project/fig4_2.jpg" class="centered-image">
                        <li>人员行为分析：评估工作人员的操作是否符合安全标准。</li>
                        <img src="../../static/picture/project/fig4_1.jpg" class="centered-image" style="max-height: 800px;">
                        <li>工业设备状态监测：提前发现设备故障，预防事故发生。</li>
                        <img src="../../static/picture/project/fig4_3.jpg" class="centered-image">
                    </ul>
                    <p class="text">
                        机器视觉的优势在于具有高精度、高速度、非接触性等特点，能够在各种恶劣环境下稳定工作。它的应用有助于提高生产效率、保证产品质量、提升工作场所的安全性。
                    </p>
                </div>
            </div>
        </div>
    </section>
    <Footer />
</template>
<script>
import Header from '../header2.vue'
import Footer from '../footer.vue'
import comon from './common_p.vue'

export default {
    name: 'Projects',
    components: {
        Header,
        Footer,
        comon
    }
}
</script>