<template>
    <footer class="footer-area footer-cont">
        <div class="container">
            <!-- <h4 class="lasthead">齐码蓝智能</h4> -->
            <div class="row justify-content-between">
                <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.2s"
                    style="visibility: visible; animation-delay: 0.2s; animation-name: fadeInUp;">
                    <div class="footer-box one">
                        <div class="logo">
                            <img src="../static/picture/qrcode.jpg" style="border-radius: 5px;height: 120px;">
                        </div>
                        <p class="text">关注公众号</p>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s"
                    style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">
                    <div class="footer-box two">
                        <div class="logo">
                            <img src="../static/picture/qywx.png" style="border-radius: 5px;height: 120px;">
                        </div>
                        <p class="text">企业微信群</p>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.4s"
                    style="visibility: visible; animation-delay: 0.4s; animation-name: fadeInUp;">
                    <div class="footer-box three">
                        <div class="logo">
                            <img src="../static/picture/discord.jpeg" style="border-radius: 5px;height: 120px;">
                        </div>
                        <p class="text">Discord</p>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.5s"
                    style="visibility: visible; animation-delay: 0.5s; animation-name: fadeInUp;">
                    <div class="footer-box none">
                        <div class="logo">
                            <img src="../static/picture/slack.jpeg" style="border-radius: 5px;height: 120px;">
                        </div>
                        <p class="text">Slack</p>
                    </div>
                </div>

            </div>
        </div>
    </footer>
    <div class="copyright two">
        <div class="container">
            <div class="row">
                <div class="col-12 wow fadeInUp text-center">
                    <text>版权所有：Copyright &copy; 2024 齐码蓝智能科技（大理市）有限责任公司</text>
                    <img src="../static/picture/gongan.png" style="height: 20px;margin-left: 15px;">
                    <a href="https://beian.mps.gov.cn/#/query/webSearch?code=53290102000705" rel="noreferrer"
                        target="_blank">滇公网安备53290102000705</a>
                    <text style="margin-left: 15px;">滇ICP备2024023558号-1</text>
                </div>
            </div>
        </div>
    </div>
</template>