<template>
   <Header class1="header-two header-home " isHome=True></Header>
   <Body />
   <Footer />
</template>

<script>
import Header from './header2.vue'
import Footer from './footer.vue'
import Body from './body.vue'

export default {
  name: 'Home',
  components: {
    Header,
    Body,
    Footer
  }
}
</script>