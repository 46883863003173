import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
import Home from '../components/home.vue'
import About from '../components/about.vue'
import Contact from '../components/contact.vue'
import Projects from '../components/projects.vue'
import P1 from '../components/aiproject/P1.vue'
import P2 from '../components/aiproject/P2.vue'
import P3 from '../components/aiproject/P3.vue'
import P4 from '../components/aiproject/P4.vue'
import ERP from '../components/erp.vue'
import A1 from '../components/erpproject/A1.vue'
import A2 from '../components/erpproject/A2.vue'
import A3 from '../components/erpproject/A3.vue'
import A4 from '../components/erpproject/A4.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/projects',
    name: 'Projects',
    component: Projects
  },
  {
    path: '/P1',
    name: 'P1',
    component: P1
  },
  {
    path: '/P2',
    name: 'P2',
    component: P2
  },
  {
    path: '/P3',
    name: 'P3',
    component: P3
  },
  {
    path: '/P4',
    name: 'P4',
    component: P4
  },
  {
    path: '/ERP',
    name: 'ERP',
    component: ERP
  },
  {
    path: '/A1',
    name: 'A1',
    component: A1
  },
  {
    path: '/A2',
    name: 'A2',
    component: A2
  },
  {
    path: '/A3',
    name: 'A3',
    component: A3
  },
  {
    path: '/A4',
    name: 'A4',
    component: A4
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router