<template>
    <Header class1="header-one " isAu=True></Header>
    <!--====== Banner Start ======-->
    <section class="breadcrumb-area">
        <div class="container">
            <div class="content">
                <h2 class="breadd wow fadeInUp">
                    智能办公
                </h2>
                <ul class="breadcrumb-list wow fadeInUp">
                    <li> <router-link to="/">首页 / </router-link></li>
                    <li>智能办公</li>
                </ul>
            </div>
        </div>
    </section>
    <!--====== Banner End ======-->
    <!--====== Unique Themes Start  ======-->
    <section class="unique themes product market">
        <div class="container">
            <div class="row">
                <div v-for="item in erplist" :key="item.id" class="col-xl-6 col-md-6 wow fadeInUp" :data-wow-delay="item.delay">
                    <div class="themes-box">
                        <div class="thumb">
                            <img :src="item.image" class="thumbimg">
                        </div>
                        <div class="main-content">
                            <h5>
                                <router-link :to="'/' + item.link">{{ item.title }}</router-link>
                            </h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--====== Unique Themes End  ======-->
    <Footer />
</template>

<script>
import Header from './header2.vue'
import Footer from './footer.vue'

export default {
    name: 'Projects',
    components: {
        Header,
        Footer
    },
    // mounted() {
    //     console.log('欢迎来到智能办公系统');
    // }
}
</script>
