<template>
    <Header class1="header-one" isPro=True isP3=True></Header>
    <!--====== Banner Start ======-->
    <section class="breadcrumb-area">
        <div class="container">
            <div class="content">
                <h2 class="breadd wow fadeInUp">
                    机器视觉在生态保护中的应用
                </h2>
                <h2 class="breadd wow fadeInUp">
                    Application of Machine Vision in Ecological Protection
                </h2>
                <comon pro="机器视觉" tit="农林畜牧" proip="projects"></comon>
            </div>
        </div>
    </section>
    <!--====== Banner End ======-->

    <section class="single-blog-details">
        <div class="container">
            <div class="row">
                <div class="blog-details">
                    <div class="main-content wow fadeInUp">
                        <div class="up-content">
                            <p class="text"><i class="fas fa-calendar-alt"></i>2023-12-28</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xl-6">
                            <div class="contentarea wow fadeInUp">
                                <p class="text">
                                    在农业和畜牧业中，机器视觉的一个主要优势是它可以自动处理那些耗时且劳动强度高的任务。随着传感器和执行器技术的日益成熟，机器视觉系统将逐渐被用于果园的采摘、作物管理、收获以及其他多种任务。农业工作者将更多地起到监督与优化角色，确保机器视觉系统的更好运作。
                                </p>
                            </div>
                        </div>
                        <div class="col-xl-6">
                            <img src="../../static/picture/project/fig3_1.jpg">
                        </div>
                    </div>
                    <p class="text">
                        机器视觉在农业、畜牧业的应用主要包括以下几个方面：
                    </p>
                    <ul class="contentul">
                        <li>
                            种子和果实分级检测：种子的质量是决定农作物的最终产量的重要因素，因此类型识别以及播种前的精选，对于提高农作物产量具有重要意义。传统的人工分选与检测耗时耗力，工作量大。利用机器视觉技术可以对获取的种子图像进行基本的几何测量，获得形状、长宽比、面积等参数，进而区分种子的类别，完成优质种子与霉变、有缺陷种子之间的筛选工作。在水果的品质分级中，机器视觉技术可以无损地提取水果的形状、颜色和大小，并制定相应的评分标准规则。基于机器视觉的自动分拣系统可以快速不间断地进行水果的品质分析，从而节省时间，减少人工劳动。
                        </li>
                        <img src="../../static/picture/project/fig3_2.jpg" class="centered-image">
                        <li>杂草和虫害监测：在番茄生长周期中，杂草控制在间接成本中所占的比例相对较高。加州大学戴维斯分校的研究人员开发了一个自动除草系统，通过使用机器视觉系统的检测和X射线清除番茄茎附近生长的杂草，改善了番茄生长条件。农作物在生长过程中容易受到病虫害的侵害，对最终产量造成负面影响。传统的大面积施药方法存在资源浪费、环境污染和破坏的问题。因此，当前机器视觉技术在农业应用中备受关注，特别是在检测和识别作物病虫害区域以及精确控制喷药机械方面。
                        </li>
                        <li>重型农机设备自动化：机器视觉可以大大增强现有的农业设备工作效率</li>
                        <img src="../../static/picture/project/fig3_3.jpg" class="centered-image">
                        <li>畜禽行为识别：机器视觉可以利用监督学习等技术，对畜禽的基本行为特点进行学习，从而实现对其行为的自动识别。</li>
                        <li>畜禽分类与鉴别：利用图像学习技术，可以对畜禽进行分类和鉴别，这对于畜禽品种和身体特征的辨识来说非常重要。</li>
                        <li>畜禽健康监测：机器视觉技术可以通过图像处理和行为识别技术的结合，及时发现畜禽疾病，替代传统的体检方式，节省高昂的人力成本。</li>
                        <li>畜禽生长评估：通过对畜禽体重、形态、长相等输出数据进行计算、分析，利用科学的方法对其生长情况进行评估，并为下一步养殖管理提供基础资料。</li>
                    </ul>
                    <p class="text">
                        机器视觉在农业和畜牧业中的应用前景广泛、意义重大：
                    </p>
                    <ul class="contentul">
                        <li>提高生产效率：机器视觉技术可以实现自动化和智能化的生产过程，减少人力劳动，提高生产效率。</li>
                        <li>提高产品质量：机器视觉技术可以对农产品和畜产品进行快速、准确的检测和分析，提高产品的质量和安全性。</li>
                        <li>降低成本：机器视觉技术可以实现精细化的农业和畜牧业管理，减少浪费和损失，降低生产成本。</li>
                        <li>推动农业现代化：机器视觉技术的应用是农业现代化的重要标志之一，可以推动农业向智能化、自动化、信息化方向发展。</li>
                        <li>保障食品安全：机器视觉技术可以对农产品和畜产品进行快速、准确的检测和分析，保障食品安全。</li>
                    </ul>
                    <p class="text">
                        总之，机器视觉技术在农业和畜牧业中的应用具有重要的意义，可以提高生产效率、降低成本、保障食品安全，推动农业现代化的发展。
                    </p>
                </div>
            </div>
        </div>
    </section>
    <Footer />
</template>
<script>
import Header from '../header2.vue'
import Footer from '../footer.vue'
import comon from './common_p.vue'

export default {
    name: 'Projects',
    components: {
        Header,
        Footer,
        comon
    }
}
</script>