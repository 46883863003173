<template>
    <header :class="class1 + ' sticky-header'">
        <div class="header-navigation">
            <div class="container-fluid d-flex align-items-center justify-content-between container-1470">
                <div class="header-left">
                    <div class="site-logo">
                        <router-link to="/">
                            <img src="../static/picture/logo.png" style="height:50px;">
                        </router-link>
                    </div>
                </div>
                <div class="header-right d-flex align-items-center justify-content-end">
                    <div class="site-nav-menu">
                        <ul class="primary-menu">
                            <li :class="isHome?'current':''">
                                <router-link to="/">首页</router-link>
                            </li>
                            <li :class="isAu?'current':''">
                                <router-link to="/ERP" class="drop" >智能办公</router-link>
                                <ul class="submenu">
                                    <li v-for="item in erplist" :key="item.id" :class="{'current': item.link === $route.name}">
                                        <router-link :to="'/' + item.link">{{ item.title }}</router-link>
                                    </li>
                                </ul>
                            </li>
                            <li :class="isPro?'current':''">
                                <router-link to="/projects" class="drop" >机器视觉</router-link>
                                <ul class="submenu">
                                    <li v-for="item in aiList" :key="item.id" :class="{'current': item.link === $route.name}">
                                        <router-link :to="'/' + item.link">{{ item.shorttitle }}</router-link>
                                    </li>
                                </ul>
                            </li>
                            <li :class="isAbout?'current':''">
                                <router-link to="/about">关于我们</router-link>
                            </li>
                            <li :class="isContact?'current':''">
                                <router-link to="/contact">联系我们</router-link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>
<script>
export default {
    props: {
        class1: String,
        isHome: Blob,
        isPro: Blob,
        isAbout: Blob,
        isContact: Blob,
        isP1: Blob,
        isP2: Blob,
        isP3: Blob,
        isP4: Blob,
        isAu: Blob,
        isA1: Blob,
        isA2: Blob,
        isA3: Blob,
        isA4: Blob,
    },
    methods: {
        
    },
    mounted(){
        
    },
    watch: {
        
    },
    computed: {
        
    },
    created(){
        
    },
    destroyed(){
        
    },
    beforeMount(){
    },
    data(){
        return {}
    },
}
</script>