<template>
   <!--====== Banner Start ======-->
   <section class="banner">
      <div class="container">
         <div class="row justify-content-center">
            <div class="col-lg-10 wow fadeInUp" data-wow-delay="0.3s">
               <div class="banner-content text-center">
                  <img src="../static/picture/logo.png" style="height: 200px; border-radius: 10px; margin: 50px" />
                  <h1 class="head">齐码蓝智能</h1>
                  <h2 class="head">ZimaBlueAI Technology Co. Ltd.</h2>
                  <p class="text">
                     致力于为中小企业提供全方位信息化及 AI 机器视觉的综合服务
                  </p>
               </div>
            </div>
         </div>
      </div>
   </section>
   <!--====== Banner End ======-->

   <!--====== Category-three Start  ======-->
   <section class="unique themes">
      <div class="container">
         <div class="row justify-content-between align-items-center">
            <div class="col-lg-10 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
               <div class="section-head">
                  <h2 class="title">智能办公</h2>
                  <p class="text">
                     我们公司以专业的技术和卓越的服务，为中小企业提供全方位的信息化解决方案。
                  </p>
               </div>
            </div>
            <div class="col-lg-2 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
               <div class="link">
                  <router-link to="/ERP" class="main-btn">
                     View All Item
                  </router-link>
               </div>
            </div>
         </div>
         <div class="row">
            <div v-for="item in erplist" :key="item.id" class="col-xl-4 col-md-6 wow fadeInUp text-center" :data-wow-delay="item.delay">
               <div class="themes-box">
                  <router-link :to="'/' + item.link">
                     <img :src="item.image" alt="" />
                  </router-link>
                  <div class="main-content">
                     <router-link :to="'/' + item.link">
                        <h5 class="lasthead">{{ item.title }}</h5>
                     </router-link>
                     <div class="last-part">
                        <div class="right">
                           <p class="text">
                              {{ item.note }}
                           </p>
                        </div>
                        
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </section>
   <!--====== Category End  ======-->

   <!--====== 机器视觉项目介绍 Start  ======-->
   <section class="unique themes three">
      <div class="container">
         <div class="row justify-content-between align-items-center">
            <div class="col-lg-9 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
               <div class="section-head">
                  <h2 class="title">机器视觉</h2>
                  <p class="text">
                     我们的产品和服务广泛应用于多个领域，包括但不限于人工智能、机器学习、计算机视觉、大数据分析、深度学习等，以提供更高效、精准和智能化的解决方案。
                  </p>
               </div>
            </div>
            <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
               <div class="link">
                  <router-link to="/projects" class="main-btn">View All Item</router-link>
               </div>
            </div>
         </div>
         <div class="row">
            <div v-for="item in aiList" :key="item.id" class="col-xl-4 col-md-6 wow fadeInUp" :data-wow-delay="item.delay">
               <div class="themes-box">
                  <router-link :to="'/' + item.link">
                     <img :src="item.image" alt="" />
                  </router-link>

                  <div class="main-content">
                     <h5>
                        <router-link :to="'/' + item.link">{{ item.title }}</router-link>
                     </h5>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </section>
   <!--====== 机器视觉项目介绍 End  ======-->
</template>