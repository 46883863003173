<template>
    <Header class1="header-one" :isContact=true></Header>
    <!--====== Banner Start ======-->
    <section class="breadcrumb-area">
        <div class="container">
            <div class="content">
                <h2 class="breadd wow fadeInUp">
                    联系我们
                </h2>
                <h2 class="breadd wow fadeInUp">
                    Contact us
                </h2>
                <ul class="breadcrumb-list wow fadeInUp">
                    <li> 
                        <router-link to="/">首页 / </router-link>
                    </li>
                    <li> 联系我们</li>
                </ul>
            </div>
        </div>
    </section>
    <!--====== Banner End ======-->

    <!--====== Map Area Start ======-->
    <section class="map-area ">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="item wow fadeInUp">
                        <p class="text">欢迎关注公众号（下滑查看）</p>
                        <p class="text">公司地址：云南省大理白族自治州大理市海东镇海东新区双创中心</p>
                        <br>
                        <img src="../static/picture/map.png">

                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--====== Map Area Start ======-->
    <Footer />
</template>
<script>
import Header from './header2.vue'
import Footer from './footer.vue'

export default {
    name: 'Contact',
    components: {
        Header,
        Footer
    }
}
</script>
<style scoped>
img{
    width: 80%;
    display: block;
    margin: 0 auto;
}
</style>