<template>
   <Header class1="header-one" isAbout=True></Header>
   <!--====== Banner Start ======-->
   <section class="breadcrumb-area">
      <div class="container">
         <div class="content">
            <h2 class="breadd wow fadeInUp">关于我们</h2>
            <h2 class="breadd wow fadeInUp">About us</h2>
            <ul class="breadcrumb-list wow fadeInUp">
               <li><router-link to="/">首页 / </router-link></li>
               <li> 关于我们</li>
            </ul>
         </div>
      </div>
   </section>
   <!--====== Banner End ======-->

   <!--====== About Content Start ======-->
   <section class="content-box">
      <div class="container">
         <div class="row">
            <div class="col-lg-12">
               <div class="content-details">
                  <h2 class="title wow fadeInUp">
                     ZimaBlueAI——关注科技、关注生命、关注真理
                  </h2>
                  <div class="box wow fadeInUp">
                     <!-- <img src="../static/picture/about_us.png" alt="" class="aboutpng" /> -->
                     <p class="text">
                        欢迎来到
                        ZimaBlueAI，这是一家致力于探索科技前沿、守护生命、揭示数据真相的先锋公司。
                        我们通过人工智能应对环保、交通、生态等领域的重大挑战，努力建设更安全、更绿色、更智能的世界。
                        我们的使命是通过先进的生物信息学、数据解码技术、压缩存储算法、分布式软硬件边缘云计算，为生命科学的发展和医疗数据的安全提供全面支持。
                        我们在隐私计算、联邦学习、大数据存储和加密技术等领域深耕多年。
                        我们为中小企业提供数据平台、小程序开发、数据治理、边缘云计算等软硬件服务，并在防伪领域提供领先的去中心化信用系统服务。
                        在教育领域，我们致力于推进中国创新教育，为人工智能时代的人才发展提供整体支持和项目式学习（PBL）解决方案。
                        与我们一起致力于科技、生活和真理，探索科技的无限可能，共同创造更美好的未来！
                     </p>
                  </div>
                  <div style="margin: 40px"></div>
                  <h2 class="title wow fadeInUp">
                     ZimaBlueAI - Focusing on Technology, Life, and Truth
                  </h2>
                  <div class="box wow fadeInUp">
                     <!-- <img src="../static/picture/about_us.png" alt="" class="aboutpng" /> -->
                     <p class="text">
                        Welcome to ZimaBlueAI, a pioneering company committed to
                        exploring the frontiers of technology, safeguarding
                        life, and unveiling the truth in data. We tackle key
                        challenges in environmental protection, transportation,
                        and ecological conservation through artificial
                        intelligence, striving to build a safer, greener, and
                        smarter world. Our mission is to provide comprehensive
                        support for the development of life sciences and the
                        security of medical data through advanced
                        bioinformatics, data decoding technologies, pressure
                        storage algorithms, and distributed software/hardware
                        edge cloud computing. We have been working in the fields
                        of privacy computing, federated learning, big data
                        storage and encryption technology for many years.
                        Offering a wide range of software and hardware services
                        including data platforms, mini-program development, data
                        governance, and edge cloud computing for small and
                        medium-sized enterprises, we also deliver leading
                        decentralized credit system services in the
                        anti-counterfeiting sector. In the field of education,
                        we are dedicated to advancing China’s innovative
                        education and offering holistic support and
                        Project-Based Learning (PBL) solutions for talent
                        development in the AI era. Join us in our commitment to
                        technology, life, and truth, as we explore the endless
                        possibilities of technology to create a better future
                        together!
                     </p>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </section>
   <!--====== About Content End ======-->
   <Footer />
</template>

<script>
import Header from './header2.vue'
import Footer from './footer.vue'

export default {
   name: 'About',
   components: {
      Header,
      Footer
   }
}

</script>