<template>
    <ul class="breadcrumb-list wow fadeInUp">
        <li> <router-link to="/">首页 / </router-link></li>
        <li> <router-link :to="'/' + proip">{{ pro }} / </router-link></li>
        <li>{{ tit }}</li>
    </ul>

</template>

<script>
export default {
    props: {
        tit: String,
        pro: String,
        proip: String
    }
}
</script>